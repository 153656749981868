import React from 'react'

const ServiceOutsourcingDevelopmentModels = () => (
  <section className="service-outsourcing py-5">
    <div className="container">
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <h4 className="seo-h2 text-sm-center">Modelos de desarrollo</h4>
          <div className="lead my-2 text-sm-center">
            Hay varios modelos de&nbsp;
            <h3 className="seo-span"> desarrollo de aplicaciones web y de externalización de aplicaciones móviles</h3>
            &nbsp; entre los que se puede elegir. Tenemos una mentalidad abierta y nos adaptamos a sus necesidades y a su producto. Lo único que nunca cambiará es nuestro alto nivel.
          </div>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">NDC</span>
            Nearshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>Una gran solución para todas las empresas europeas</strong>. Los miembros de nuestro equipo hablan inglés y alemán. Nearshore outsourcing y la mentalidad occidental nos facilitan establecer relaciones comerciales a largo plazo sin fronteras.&nbsp;
        
          </div>
          <div className="text-sm text-muted">
          Gracias a las magníficas conexiones aéreas entre Polonia y otros países, podemos reunirnos regularmente con nuestros socios de Europa. Ahora puede centrarse en su negocio y dejarnos crear aplicaciones móviles y web asequibles para usted. Nuestro equipo es un grupo de expertos técnicos con talento y pasión que trabajan con metodologías ágiles. Nos complace invitarle a nuestra oficina en Wroclaw, la capital polaca de la tecnología. 
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">ODC</span>
            Offshore <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>
            Diseñado para los socios comerciales de Estados Unidos, Canadá, Escandinavia, Asia y Australia <br>
            </br>
            </strong>
             que valoran a los expertos debidamente cualificados y bien formados, la gestión en inglés, las normas legales y de seguridad europeas y el código de alta calidad.
            &nbsp; 
          </div>

          <div className="text-sm text-muted">
          ODC nos permite trabajar con clientes de todo el mundo. El desarrollo de offshoring en Polonia es muy beneficioso para los socios extranjeros: Polonia es miembro de la Unión Europea y del Acuerdo de Schengen, por lo que el tipo de cambio es muy estable y los costes del proyecto son fáciles de calcular. Las normas a escala internacional, la eficacia y la rapidez nos convierten en el socio comercial ideal a largo plazo.
          </div>
        </div>
        <div className="col-sm-12 col-md-4">
          <h2 className="seo-h3 mt-2">
            <span className="text-muted display-4 float-left mr-3">DDC</span>
            Dedicated <br />
            <span className="text-success">
              development <br />
              center
            </span>
          </h2>
          <br className="clearfix" />
          <div className="seo-p">
            <strong>Ideal para las grandes agencias de TI </strong>que necesitan externalizar una cierta parte de trabajo sin contratar nuevo personal.
          </div>
          <div className="text-sm text-muted">
          Este modelo permite contratar a un equipo de <strong> expertos a tiempo completo</strong>. Nuestros especialistas conocen el mercado y, como solo trabajarán en su proyecto, también conocerán bien su producto, sabiendo exactamente cómo llevarlo al nivel más alto. Lo único que tiene que hacer es designar a uno de sus empleados para que le oriente y reciba informes. Si está interesado, envíenos un resumen de su proyecto y le proporcionaremos un presupuesto personalizado para la contratación de un equipo de desarrolladores exclusivo.
            <h3 className="seo-span">dedicated development team</h3>.
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingDevelopmentModels
