import React from 'react'
import { Category, scrollToForm } from '../../contact/ContactForm'

const ServiceOutsourcingOfferCta = () => (
  <section className="service-web">
    <div className="container">
      <div className="row py-5">
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="lead text-left text-sm-right">
            <div className="lead text-muted">
              <h1 className="seo-span text-success">
              Outsourcing de software a Polonia
              </h1>
              <br />
              llevará su negocio al siguiente nivel
            </div>
            <a
              href="#contact-partial"
              data-form-category="outsourcing"
              className="btn btn-success my-2"
              id="software-outsourcing-more-btn"
              onClick={event => {
                event.preventDefault()
                scrollToForm(Category.FreeConsultation, 0, 500)
              }}
            >
              Obtenga una consulta gratuita
            </a>
          </div>
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-6">
          <div className="seo-p">
          Nuestro equipo de desarrollo nearshore está listo para empezar a trabajar en su aplicación (también establecer los requisitos y el alcance del trabajo). La externalización al extranjero ayuda a desarrollar el proyecto sin necesidad de contratar nuevos empleados y usted solo paga por lo que realmente recibe. Al subcontratar a Polonia, puede ahorrar hasta un 30% de los costes, y un equipo especial de Zaven se convertirá en una parte integral de su empresa.
          </div>
          <p className="text-sm text-muted">
          Nuestro equipo de&nbsp;
            <strong className="seo-regular">
            nearshore/offshore outsourcing
            </strong>
            &nbsp;incluye a los mejores profesionales: desarrolladores de nivel junior y senior, probadores de QA, Gestores del proyecto, Business Analysts, SCRUM masters y gestores personales del cliente.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOfferCta
