import React from 'react'
import ServiceJumbotron from '../../components/services/ServiceJumbotron'
import NavBar from '../../components/common/NavBar'
import ContactBottom from '../../components/contact/ContactBottom'
import Technologies from '../../components/common/Technologies'
import Divider from '../../components/common/Divider'
import Faq from '../../components/common/Faq'
import SingleTestimonial from '../../components/services/SingleTestimonial'
import ServiceOutsourcingHireUsCta from '../../components/services/outsourcing/ServiceOutsourcingHireUsCta'
import ServiceOutsourcingOffer from '../../components/services/outsourcing/ServiceOutsourcingOffer'
import ServiceOutsourcingDevelopmentModels from '../../components/services/outsourcing/ServiceOutsourcingDevelopmentModels'
import ServiceOutsourcingZavenDreamTeam from '../../components/services/outsourcing/ServiceOutsourcingZavenDreamTeam'
import ServiceOutsourcingOfferCta from '../../components/services/outsourcing/ServiceOutsourcingOfferCta'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

const SoftwareOutsourcingPage = ({ data, location }) => {
  const description = data.PageInfoOutsourcing.description.split('%')
  const {
    email,
    phone,
    skype,
    phoneClean,
    skypeName,
    workTimeUTC,
  } = data.info_outsourcing.contact
  const { city, street, country, url } = data.info_outsourcing.address.office
  const technologies = data.technologies_outsourcing.edges
  const testimonial = data.testimonials_outsourcing
  const {
    title,
    features,
    pageTitle,
    metaDescription,
    keywords,
  } = data.PageInfoOutsourcing
  const { legalName } = data.info_outsourcing
  const faqs = data.faq_outsourcing.edges.map(e => ({
    question: e.node.question,
    answer: e.node.answer,
    id: e.node.id,
  }))

  const mailingConfig = data.mailing_info_outsourcing
  const { servicesIlustrationOutsourcing } = data

  return (
    <>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: metaDescription },
          { name: 'keywords', content: keywords },
        ]}
      />
      <NavBar location={location} />
      <ServiceJumbotron
        colorAccentClass={'service-outsourcing'}
        image={servicesIlustrationOutsourcing.fluid.src}
        title={title}
        description={[
          <p key={'a'} className="lead">
            {description[0]}
            <strong key={'b'}>{description[1]}</strong>
            {description[2]}
          </p>,
        ]}
        infoPackUrl={`${process.env.GATSBY_MAILING_URL}/api/infopack`}
        features={features}
      >
        <p>
        Aproveche uno de los mercados de subcontratación más atractivos del mundo. Acceso a conocimientos y experiencia de primer nivel en el desarrollo <strong>de aplicaciones web y móviles</strong>.
        </p>
        <h3 className="seo-h4">Ventajas de la subcontratación en POLONIA</h3>
        <ul>
          <li>Costes significativamente menores</li>
          <li>Especialistas altamente cualificados</li>
          <li>Enfoque empresarial orientado a los objetivos</li>
          <li>Acceso a tecnologías innovadoras</li>
          <li>Optimización de la financiación de las inversiones</li>
          <li>Desarrollo de software de alta calidad</li>
          <li>Ser más competitivo en el mercado</li>
          <li>Al unirse, pasamos a formar parte de su equipo</li>
        </ul>
      </ServiceJumbotron>
      <SingleTestimonial testimonial={testimonial} />
      <Divider />
      <ServiceOutsourcingHireUsCta />
      <ServiceOutsourcingOffer />
      <ServiceOutsourcingDevelopmentModels />
      <ServiceOutsourcingZavenDreamTeam />
      <ServiceOutsourcingOfferCta />
      <Divider />
      <Technologies
        listOfTechnologies={technologies}
        style={{ padding: '3.25rem 0 3.25rem 0' }}
      />
      <ContactBottom
        city={city}
        skype={skype}
        street={street}
        country={country}
        legalName={legalName}
        phoneClean={phoneClean}
        workTimeUTC={workTimeUTC}
        skypeName={skypeName}
        phone={phone}
        email={email}
        addressUrl={url}
        mailingConfig={mailingConfig}
      />
      <Divider fullWidth rainbow />
      <Faq listOfFaqs={faqs} />
    </>
  )
}

export default SoftwareOutsourcingPage

export const query = graphql`
  query ItOutsourcingPageQuery {
    info_outsourcing: dataJson {
      ...Contact
    }
    technologies_outsourcing: allTechnologiesJson {
      ...Technologies
    }
    PageInfoOutsourcing: servicesYaml(title: { eq: "Outsourcing IT" }) {
      title
      pageTitle
      metaDescription
      keywords
      description
      features {
        icon
        title
        description
      }
    }
    faq_outsourcing: allFaqJson(
      filter: { displayIn: { eq: "services_outsourcing" } }
    ) {
      ...Faqs
    }
    testimonials_outsourcing: testimonialsClientsJson(
      displayIn: { eq: "services_outsourcing" }
    ) {
      name
      company
      message
      avatar {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      role
      social {
        icon
        url
      }
    }
    servicesIlustrationOutsourcing: imageSharp(
      fluid: { originalName: { regex: "/services_illustration_outsourcing/" } }
    ) {
      fluid(quality: 85) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
