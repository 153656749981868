import React from 'react'

const ServiceOutsourcingZavenDreamTeam = () => (
  <section className="partial-cta-mvp bg-dark">
    <div className="container">
      <p className="lead text-center">
        <strong>Contrate el equipo de sus sueños de Zaven:</strong>{' '}
        <span className="text-success">rápido, eficaz y orientado a los objetivos.</span>
      </p>
    </div>
  </section>
)

export default ServiceOutsourcingZavenDreamTeam
