import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faClock,
  faEye,
  faGem,
  faUmbrella,
} from '@fortawesome/pro-light-svg-icons'

const ServiceOutsourcingOffer = () => (
  <section className="service-outsourcing py-5 bg-faded">
    <div className="container">
      <div className="row mb-5">
        <div className="col-sm-12 col-md-4">
          <h4 className="seo-h2 text-sm-center text-md-right mt-0">
            Time & materials
            <br />
            modelo de precios
          </h4>
        </div>
        <div className="col-sm-12 col-md-8">
          <div className="card-group mb-5">
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faEye}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Transparencia</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Con los pagos mensuales, es más fácil mantener el control financiero de su proyecto. Sin costes ocultos, sin sorpresas.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faClock}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Disponibilidad</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                 Programadores que trabajan únicamente en su proyecto
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faUmbrella}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Seguridad</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                 El código es siempre seguro gracias a los acuerdos relativos a la seguridad y las políticas de trabajo que siguen estrictamente los procedimientos de seguridad.
                </p>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <FontAwesomeIcon
                  icon={faGem}
                  size="2x"
                  className="text-success mb-2"
                />
                <br />
                <strong>Calidad</strong>
                <br />
                <p className="text-sm text-muted text-italic">
                Nuestro equipo de desarrollo está formado por desarrolladores experimentados y  centrados en los objetivos empresariales.
                </p>
              </div>
            </div>
          </div>
          <p>
            <strong>Time & Materials</strong> es un modelo de pago mutuamente beneficioso. La transparencia de este modelo hace queel cliente tenga más control sobre los costes y solo paga por el trabajo real. Este método de presupuestación permite mantener un flujo de trabajo ágil y financiar el proyecto en cómodos plazos.

          </p>
          <p>
           Las principales ventajas de <strong>Time & Materials</strong> son:
          </p>
          <ul>
            <li>Control de calidad continuo</li>
            <li>Factores de riesgo bajos</li>
            <li>Sin costes adicionales</li>
            <li>Apoyo al Gestor del Proyecto para agilizar el flujo de trabajo</li>
          </ul>
          <p>
          Es una solución genial para las nuevas empresas que a menudo carecen de especificaciones detalladas del producto,
            <strong className="seo-span"> prototipos de aplicaciones</strong> y requisitos de tiempo.
          </p>
        </div>
      </div>
    </div>
  </section>
)

export default ServiceOutsourcingOffer
